import React, {useState} from "react";
import axios from "axios";

const enumColor = {
  1: "#ffffff",
  2: "#ffdd46",
  3: "#ff4444",
  4: "#00ff00",
};

const ChangeColorButton = ({event}) => {
  const [status, setStatus] = useState(event.dropshipping.status);
  const [showPopover, setShowPopover] = useState(false);
  let hidePopoverTimeout;

  const onClick = async (newStatus) => {
    setStatus(newStatus);
    await axios.put(`https://ds.plessinc.com/api/events/adds/update/${event.event_id}`, {status: newStatus})
    await axios.put(`https://ds.plessinc.com/api/events/status/${event.event_id}`, {status: newStatus})

    setShowPopover(false);
  };

  const handleMouseEnter = () => {
    clearTimeout(hidePopoverTimeout);
    setShowPopover(true);
  };

  const handleMouseLeave = () => {
    hidePopoverTimeout = setTimeout(() => {
      setShowPopover(false);
    }, 200);
  };

  return (
    <div style={{position: "relative", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <i
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          cursor: "pointer",
          color: enumColor[status],
          fontSize: "32px",
        }}
        className="fa fa-certificate"
      />
      {showPopover && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {Object.entries(enumColor).map(([key, color]) => (
            <div
              key={key}
              onClick={() => onClick(parseInt(key))}
              style={{
                backgroundColor: color,
                width: "20px",
                height: "20px",
                margin: "5px",
                cursor: "pointer",
                border: key === "1" ? "2px solid gray" : "none",
                position: "relative",
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ChangeColorButton;
